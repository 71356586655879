<script>
import api, { ApiDefaults } from '@/utils/api_call';
import EventsBus from '@/utils/EventsBus';
import { STORE_MEDIATHEK } from '@/utils/store/actions/general';
import { debounce } from 'lodash';
import { mapGetters } from 'vuex';
// import Youtube from 'vue3-youtube';
import KappNews from '@/components/Blocks/KappNews.vue';
import KappCategoryNav from '@/components/Subparts/KappCategoryNav.vue';

export default {
	name : 'Mediathek',
    components:{
		KappCategoryNav,
		// 'youtube' : Youtube
        'news' : KappNews
    },
    data() {
		return {
			categories : [],
			showCategoryNav: false,
			animDirection: 'slide-left',
			categoryFilter: 0,
			like_settings: {pid: this.$route.meta.uid, target: 'mediathek'},
		}
    },
    computed: {
		...mapGetters([ 'mediaItems', 'mediaItemsByCategory']),
		filteredMedia: function () {

			if ( !this.mediaItems ) return [];

			if(this.categoryFilter > 0) return this.mediaItemsByCategory( this.categoryFilter );

			return this.mediaItems;
		}
    },
    methods:{
		openNews: function(article) {
			this.$router.push({path:'/media/' + article.uid });
		},
		fetchData: debounce(function () {

			let apiParams = Object.assign({}, ApiDefaults, {
				method: 'get',
                params: {
					id: 18,
					'tx_c3json_jsondatanews[action]': 'news'
				}
			});
			api(apiParams)
				.then(( response ) => {

					if ( response.status === 200 ) {
						let articles = [];
						articles = response.data.news.content.map(( item ) => {

                           // item.categories = item.categories;
                           item.visible = true;

                           return item;
                       })
                       .sort(( a, b ) => {
                           return b.datetime - a.datetime;
                       });
						this.categories = Object.values(response.data.news.categories);
						EventsBus.trigger('showCategories', this.categories);

						this.$store.commit(STORE_MEDIATHEK, articles);
						// this.$store.commit(STORE_BULLETIN_CATEGORIES, this.categories);
					}
				})
				.catch(( err ) => {
					console.warn(err.message);
				})
				.finally(() => this.hideArticleEffect = 'hideArticles');


		}, 125),
		switchCategory: function ( val ) {
			if ( val === '' ) {
				this.animDirection = 'news-right';
				val = 0;
			}
			else {
				val = val.toString().split('-')[ 1 ];

				if ( val < this.categoryFilter )
					this.animDirection = 'news-right';

				else
					this.animDirection = 'news-left';
			}
			console.log( val );

			this.$nextTick(() => this.categoryFilter = parseInt(val));
		},
    },
	created() {
		this.fetchData();
		// EventsBus.on('lightbox:closed', this.resetLightbox);
		EventsBus.on('switchContentCategory', this.switchCategory);
	}
}
</script>

<template>
    <div>
        <KappCategoryNav :categories="categories"/>

        <div class="list list--article">
            <transition-group :name="animDirection">
            <news v-for="(item,idx) in filteredMedia"
                  :article="item"
                  :uid="item.uid"
                  :key="idx"
                  class="article"
                  layout="bulletin"
                  :like_settings="like_settings"
                  :teaserLength="60"
                  @click="openNews(item)" />
            </transition-group>
        </div>
    </div>
</template>

<style scoped>

</style>